/*eslint-disable sonarjs/cognitive-complexity*/
/*eslint-disable react-hooks/exhaustive-deps*/
/**
 * PS_PG_01
 * Import packages, interface and context
 */
import React, { useEffect } from 'react';
import { useContext, useState } from 'react';
import { takePaymentStyles } from '../../stylesJS/makeStyles';
import { customerStyles } from '../../stylesJS/customerStyles';
import {
  RACButton,
  RACModalCard,
  RACTable,
  RACTableRow,
  RACTableCell,
  Grid,
  List,
  ListItem,
  Card,
  Box,
  RACTooltip,
} from '@rentacenter/racstrap';
import { agreementContext } from '../context/PaymentInformationContext';
import OutsideClickHandler from 'react-outside-click-handler';
import AccordianOpenIcon from '../../assets/images/opened-accordian.png';
import AccordianCloseIcon from '../../assets/images/closed-accordian.png';
import HandleFreeTime from './FreeTime';
import { CCCBComponent } from './CCCBComponent';
import SecondFactor from '../../racstrapComponents/microfrontends/TwoFactorPopup';
import { NSFComponent } from './NSFComponent';
import moment from 'moment';
import {
  AddSuspenseComponent,
  TransferSuspenseComponent,
  UseSuspenseComponent,
} from './HandleSuspenseComponent';
import {
  dateFormat,
  dueDateFormat,
  ellipsisFormat,
  schedule,
  totalDueAmount,
} from '../utils/scheduleAndDateFormat';
import { useHistory, useParams } from 'react-router-dom';
import { getRetentionOffers } from '../../api/user';
import HandleAdjustDueDate from './AdjustDueDate';
import Autopay from './HandleAutopay';
import { ReactComponent as RetentionIcon } from './../../assets/images/Retention.svg';
import { ReactComponent as ExchangeIcon } from '../../assets/images/Exchange.svg';
import RetentionComponent from './Retention';
import { RacExchangePopup } from './RACExchange';
import { calculateTotalDueAmount } from '../utils/calculateTotalDueAmount';
import CONSTANTS from '../constants/constant';
import { agreementIdAndNumber } from '../interface/racExchangePopupInterface';
import AcceptEpo from './AcceptEPO';
import { paymentGridStyle } from '../stylesJS/paymentGridStyle';
import { ChargeOffDetails, ParamType } from '../interface/commonInterface';
import {
  AgreementDetailsBasedOnStore,
  AgreementTypeObject,
  AuthDetailsObject,
  BackRentEditInfo,
  ButtonNameObject,
  StoreDetails,
} from '../interface/paymentGridInterface';
import { AgreementContextValue } from '../interface/contextInterface';
import { AgreementDetails } from '../interface/paymentInfoInterface';
import { ChargeOffComponent } from './otherIncome/ChargeOff';
import BackRentComponent from './otherIncome/BackRentPopup';
import EpoSchedule from './ViewEpoSchedule';

/**
 * PS_PG_02 - PS_PG_157
 * Declaring a function as PaymentGrid()
 * @returns
 */
export function PaymentGrid() {
  /**
   * PS_PG_04 - PS_PG_17
   * Declaring variables and destructing from context
   */
  const classes = takePaymentStyles();
  const internalClasses = paymentGridStyle();
  const classesForExchange = customerStyles();
  const history = useHistory();

  /**
   * PS_PG_04
   * Initializing an object with keys for buttons
   */
  const buttonNamesObject: ButtonNameObject = {
    setToDefault: false,
    adjustChargeOff: false,
    adjustRentalItemSale: false,
    suspense: false,
    freeTime: false,
    adjustDueDate: false,
    adjustNSF: false,
    adjustCCCB: false,
    epo: false,
    autopay: false,
    removeAmountDue: false,
    retention: false,
    racExchange: false,
    suspenseOptions: false,
    epoOptions: false,
    acceptEpo: false,
  };

  const [buttonEnable, setButtonEnable] =
    useState<ButtonNameObject>(buttonNamesObject);
  const [buttonView, setButtonView] =
    useState<ButtonNameObject>(buttonNamesObject);
  const { customerId, agreementId } = useParams<ParamType>();
  const [loaderSetToDefatult, setLoaderSetToDefault] = useState<boolean>(false);
  const [autopayPopup, setAutopayPopup] = useState<boolean>(false);

  /**
   * PS_PG_06
   * This state variable is responsible for authentications
   */
  const [authDetails, setAuthDetails] = useState<AuthDetailsObject>({
    cccbAuth: false,
    nsfAuth: false,
    racExchangeAuth: false,
    retentionAuth: false,
  });

  const [accordionIndex, setAccordionIndex] = useState<string | null>();
  /**
   * PS_PG_07
   * Destructing agreement context
   */
  const {
    agreementDetails,
    setAgreementDetails,
    otheIncomeChargeOffDetails,
    agreementDetailsDeepClone,
    storeInfo,
    featureFlags,
    coWorkerDetails,
    multiStoreSelected,
    setMultiStoreSelected,
    customerInfo,
    setCustomerInfo,
    isSetToDefault,
    setIsSetToDefault,
    renderContext,
    setRenderContext,
    customerOrderPayment,
    customerInfoDeepClone,
    cappedFreeTimeDetails,
    setCappedFreeTimeDetails,
    modalCardEnable,
    setModalCardEnable,
    backRentInfo,
    removeAmountDue,
    setRemoveAmountDue,
    otherActionLoader,
    nonInventorySaleInfo,
    setNonInventorySaleInfo,
    otherIncomeChargeOffDetailsDeepClone,
    setOtheIncomeChargeOffDetails,
    setCommitmentLoad,
    suspenseUseCopy,
    setSuspenseUseCopy,
  } = useContext<AgreementContextValue>(agreementContext);

  const agreementDetailsClone: AgreementDetails[] = JSON.parse(
    JSON.stringify(agreementDetails)
  );

  const selectedAgreement: AgreementDetails[] = [];
  const selectedId: string[] = [];
  const disabledId: string[] = [];
  const disableSelectedId: string[] = [];
  const exchangeId: string[] = [];

  agreementDetailsClone?.forEach((eachAgreement: AgreementDetails) => {
    if (eachAgreement.selected) {
      selectedAgreement.push(eachAgreement);
      if (eachAgreement.agreementNumber == CONSTANTS.ADJUST)
        selectedId.push(String(eachAgreement.miscCounters));
      else selectedId.push(eachAgreement.agreementNumber);
    }
    if (eachAgreement.disabled && !eachAgreement.isExchanged) {
      disabledId.push(eachAgreement.agreementNumber);
      if (eachAgreement.selected)
        disableSelectedId.push(eachAgreement.agreementNumber);
    }
    if (eachAgreement.isExchanged)
      exchangeId.push(eachAgreement.agreementNumber);
  });

  /**
   * To check for the club checkbox disable when club is deactivated
   */
  const clubAgreementDetail = agreementDetailsClone.find(
    (el: AgreementDetails) => el.agreementType == CONSTANTS.CLUB
  );

  const sessionStoreNumber: string =
    sessionStorage.getItem(CONSTANTS.STORE_NUMBER) ?? CONSTANTS.EMPTY_STRING;

  /**
   * PS_PG_11
   * This object is responsible for handling different type of agreements
   */
  const agreementsTypeObject: AgreementTypeObject = {
    sameScheduleAgreements: [],
    transferAgreementStore: [],
    transferAgreement: [],
    chargeOffAgreeement: [],
    rentalItemSaleAgreement: [],
    nsfCheckAgreement: [],
    cccbAgreement: [],
    promoAgreement: [],
    backRentAgreement: [],
    clubAgreement: [],
    notePaymentAgreement: [],
    differentStoreAgreement: [],
    sacPifEpoAgreement: [],
    initialPaymentAgreement: [],
    zeroPaymentAgreemnets: [],
    eppAgreements: [],
    autopayAgreements: [],
    returnAgreements: [],
    serviceAgreements: [],
    otherIncomeAgreements: [],
    pifNotePaymentAgreement: [],
    sacPifEpoRentAgreements: [],
    ldwAgreements: [],
    policyAgreements: [],
  };

  const [agreementsType, setAgreementsType] =
    useState<AgreementTypeObject>(agreementsTypeObject);

  let sameSchedule = CONSTANTS.ZERO_NUMBER;
  let freeTimeEdited = CONSTANTS.ZERO_NUMBER;
  const gridHeaderFields = CONSTANTS.PAYMENT_GRID_HEADER;

  const [
    agreementDetailsBasedOnStoreNumbers,
    setAgreementDetailsBasedOnStoreNumbers,
  ] = useState<AgreementDetailsBasedOnStore[]>([]);
  const [selectedRetentionOrExchangeId, setSelectedRetentionOrExchangeId] =
    useState<agreementIdAndNumber>({
      agreementId: CONSTANTS.EMPTY_STRING,
      agreementNumber: CONSTANTS.EMPTY_STRING,
    });

  //payment footer variable declarations
  const [backRentPopup, setBackRentPopup] = useState<boolean>(false);
  const [backRentEditInfo, setBackRentEditInfo] = useState<BackRentEditInfo>();
  const [editChargeoffDatas, setEditChargeoffDatas] =
    useState<ChargeOffDetails[]>();
  const [chargeOffPopupFlag, setChargeOffPopupFlag] = useState<boolean>(false);
  /**
   * PS_PG_18 - PS_PG_22
   * Within this useEffect(), Form an array of object with store number as key
   * and respective agreemets to that store as value for it
   */
  useEffect(() => {
    const organizedAgreementObject: AgreementDetailsBasedOnStore = {};

    const clubObject = agreementDetails?.filter(
      (el) => el.agreementType == CONSTANTS.CLUB
    );
    if (clubObject.length)
      organizedAgreementObject[CONSTANTS.CLUB] = clubObject;

    const sameStoreObject = agreementDetails.filter(
      (el) =>
        el?.storeNumber == sessionStoreNumber &&
        el?.agreementType != CONSTANTS.CLUB
    );
    if (sameStoreObject.length)
      organizedAgreementObject[sessionStoreNumber] = sameStoreObject;

    agreementDetails?.forEach((item) => {
      if (
        item?.storeNumber != sessionStoreNumber &&
        item?.agreementType != CONSTANTS.CLUB
      ) {
        if (!organizedAgreementObject[item?.storeNumber])
          organizedAgreementObject[item?.storeNumber] = [];
        organizedAgreementObject[item?.storeNumber].push(item);
      }
    });
    setAgreementDetailsBasedOnStoreNumbers(
      Object.entries(organizedAgreementObject).map(([key, value]) => ({
        [key]: value,
      }))
    );
  }, [renderContext, agreementDetails]);

  /**
   * PS_PG_23 - PS_PG_48
   * Push agreement numbers with respect to the type of it
   * call handleGridButtons()
   */
  useEffect(() => {
    const agreementsTypeObjectUpdated = processAgreements();
    setMultiStoreSelected(
      agreementsTypeObjectUpdated.differentStoreAgreement.length >
        CONSTANTS.ZERO_NUMBER
    );
    setAgreementsType(agreementsTypeObjectUpdated);
    handleGridButtons();
  }, [renderContext, otherActionLoader, agreementDetails]);

  useEffect(() => {
    if (multiStoreSelected) {
      const sameStoreAgr = agreementDetails.filter(
        (el: AgreementDetails) => el.storeNumber == sessionStoreNumber
      );
      setSuspenseUseCopy(JSON.parse(JSON.stringify(sameStoreAgr)));
      setAgreementDetails(
        agreementDetails.map((el: AgreementDetails) => {
          if (el.isOverSus || el.isEPOSusAmtDue || el.isExactSus) {
            el.totalDueAmount = el.epoAmount;
            el.totalAmount = el.epoAmount;
            el.totalTax = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
            delete el.isOverSus;
            delete el.isEPOSusAmtDue;
            delete el.isExactSus;
          } else if (
            el.isFinalPayment &&
            CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(el.isFinalPayment)
          ) {
            el.totalDueAmount = totalDueAmount(
              el.totalAmount,
              el.totalTax
            ).toFixed(2);
          }
          el.suspenseDetails.suspUse = CONSTANTS.ZREO_TWO_DECIMAL_STRING;

          return el;
        })
      );
    } else if (suspenseUseCopy.length) {
      setAgreementDetails(
        agreementDetails.map((el: AgreementDetails) => {
          if (el.storeNumber == sessionStoreNumber) {
            const agr = suspenseUseCopy.find(
              (agr: AgreementDetails) =>
                el.agreementNumber == agr.agreementNumber
            );
            if (agr) agr.selected = el.selected;
            el = agr ?? el;
          }
          return el;
        })
      );
    }
  }, [multiStoreSelected]);

  const processAgreements = () => {
    storeInfo?.storeDetails?.forEach((item: StoreDetails) => {
      if (item.transferAgreementStatus === CONSTANTS.ONE_NUMBER) {
        agreementsTypeObject.transferAgreementStore.push(item.storeNumber);
      }
    });

    const firstAgreement = selectedAgreement?.[0];

    selectedAgreement?.forEach((item: AgreementDetails) => {
      processAgreementType(item, agreementsTypeObject);

      if (item.storeNumber !== sessionStoreNumber) {
        agreementsTypeObject.differentStoreAgreement.push(item.agreementNumber);
      }

      if (CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(item.nextDueDate)) {
        agreementsTypeObject.sacPifEpoAgreement.push(item.agreementNumber);
      }

      if (item.agreementSchedule === firstAgreement?.agreementSchedule) {
        if (item.currentDueDate === firstAgreement?.currentDueDate) {
          agreementsTypeObject.sameScheduleAgreements.push(
            item.agreementNumber
          );
        }
        sameSchedule++;
      }

      if (item.isFreeTimeEdit) freeTimeEdited++;

      processAdditionalProperties(item, agreementsTypeObject);
    });

    return agreementsTypeObject;
  };

  const processAgreementType = (
    item: AgreementDetails,
    agreementsTypeObject: AgreementTypeObject
  ) => {
    const agreementTypeMap = {
      [CONSTANTS.RESTITUTION]: CONSTANTS.CHARGE_OFF_AGREEMENT,
      [CONSTANTS.RENTAL_ITEM_SALE]: CONSTANTS.RENTAL_ITEM_SALE_AGREEMENT,
      [CONSTANTS.NSF_CHECK]: CONSTANTS.NSF_CHECK_AGREEMENT,
      [CONSTANTS.CREDIT_CARD_CHARGE_BACK]: CONSTANTS.CCCB_AGREEMENT,
      [CONSTANTS.PROMO]: CONSTANTS.PROMO_AGREEMENT,
      [CONSTANTS.BACK_RENT]: CONSTANTS.BACK_RENT_AGREEMENT,
      [CONSTANTS.CLUB]: CONSTANTS.CLUB_AGREEMENT,
      [CONSTANTS.NOTE_PAYMENT]: CONSTANTS.NOTE_PAYMENT_AGREEMENT,
      [CONSTANTS.PIF_NOTE]: CONSTANTS.PIF_NOTE_AGREEMENTS,
      [CONSTANTS.EPO_RENT]: CONSTANTS.SAC_PIF_EPO_RENT_AGREEMENTS,
      [CONSTANTS.PIF_RENT]: CONSTANTS.SAC_PIF_EPO_RENT_AGREEMENTS,
      [CONSTANTS.SAC_RENT]: CONSTANTS.SAC_PIF_EPO_RENT_AGREEMENTS,
    };

    const agreementTypeKey = agreementTypeMap[item.agreementType];
    if (agreementTypeKey) {
      agreementsTypeObject[agreementTypeKey].push(item.agreementNumber);
    }
  };

  const processAdditionalProperties = (
    item: AgreementDetails,
    agreementsTypeObject: AgreementTypeObject
  ) => {
    const conditions = [
      {
        check: item.numberOfPaymentsMade === CONSTANTS.ZERO_NUMBER,
        key: CONSTANTS.INITIAL_PAYMENT_AGREEMENT,
      },
      {
        check: agreementsTypeObject.transferAgreementStore.includes(
          item.storeNumber
        ),
        key: CONSTANTS.TRANSFER_AGREEMENT,
      },
      { check: item.isZeroPayment, key: CONSTANTS.ZERO_PAYMENT_AGREEMENTS },
      { check: item.isEpp, key: CONSTANTS.EPP_AGREEMENTS },
      { check: item.isAutopayEnroll, key: CONSTANTS.AUTOPAY_AGREEMENTS },
      { check: item.isReturnAgreement, key: CONSTANTS.RETURN_AGREEMENTS },
      { check: item.isItemInService, key: CONSTANTS.SERVICE_AGREEMENTS },
      { check: item.otherIncomeType, key: CONSTANTS.OTHER_INCOME_AGREEMENTS },
      { check: item.isLDW, key: CONSTANTS.LDW_AGREEMENTS },
      { check: item.isPolicy, key: CONSTANTS.POLICY_AGREEMENTS },
    ];

    conditions.forEach(({ check, key }) => {
      if (check) agreementsTypeObject[key].push(item.agreementNumber);
    });
  };
  /**
   * PS_PG_49 - PS_PG_50
   * call getFreeTimeOffersDetails()
   */
  useEffect(() => {
    /**
     * PS_PG_15
     * Displaye action field only for normal rental flows
     */
    if (
      !gridHeaderFields.some((el) => el.display == CONSTANTS.ACTION) &&
      customerOrderPayment == CONSTANTS.EMPTY_STRING
    ) {
      gridHeaderFields.push({ display: CONSTANTS.ACTION });
    }
  }, [customerOrderPayment]);

  useEffect(() => {
    /**
     * getFreeTimeOffersDetails api call only for capped free time allowed stores
     */
    featureFlags.CappedFreeTimeEnabled && getFreeTimeOffersDetails();
  }, []);
  /**
   * PS_PG_51 - PS_PG_55
   * Declare a function as getFreeTimeOffersDetails()
   * This function makes an api call to get store remaining amount
   */
  const getFreeTimeOffersDetails = async () => {
    let remainingAmount = CONSTANTS.ZERO_NUMBER;
    const payload = {
      agreementNumbers: CONSTANTS.NULL,
      percentage: featureFlags?.CappedFreeTimePercentage?.toString(),
      storeNumber: sessionStoreNumber,
    };
    const offersResponse = await getRetentionOffers(payload);
    const monthSummary = offersResponse?.data?.monthSummary;
    const responseData = {
      smrrPercentage: offersResponse?.data?.smrPercentageAmount
        ? Number(offersResponse?.data?.smrPercentageAmount)
        : CONSTANTS.ZERO_NUMBER,
      delTotalAmount:
        featureFlags?.CappedFreeTimeInitPayment &&
        monthSummary?.ftDelTotalAmount
          ? Number(monthSummary?.ftDelTotalAmount)
          : CONSTANTS.ZERO_NUMBER,
      otherTotalAmount:
        featureFlags?.CappedFreeTimeRegular && monthSummary?.ftOtherTotalAmount
          ? Number(monthSummary?.ftOtherTotalAmount)
          : CONSTANTS.ZERO_NUMBER,
      promoTotalAmount:
        featureFlags?.CappedFreeTimePromo && monthSummary?.ftPromoTotalAmount
          ? Number(monthSummary?.ftPromoTotalAmount)
          : CONSTANTS.ZERO_NUMBER,
      reiTotalAmount:
        featureFlags?.CappedFreeTimeRetention && monthSummary?.ftReiTotalAmount
          ? Number(monthSummary?.ftReiTotalAmount)
          : CONSTANTS.ZERO_NUMBER,
      serviceTotalAmount:
        featureFlags?.CappedFreeTimeService &&
        monthSummary?.ftServiceTotalAmount
          ? Number(monthSummary?.ftServiceTotalAmount)
          : CONSTANTS.ZERO_NUMBER,
    };
    remainingAmount =
      responseData.smrrPercentage -
      (responseData.delTotalAmount +
        responseData.otherTotalAmount +
        responseData.promoTotalAmount +
        responseData.reiTotalAmount +
        responseData.serviceTotalAmount);
    setCappedFreeTimeDetails({
      ...cappedFreeTimeDetails,
      storeRemainingAmount: remainingAmount
        ? remainingAmount
        : CONSTANTS.ZERO_TWO_DECIMAL_NUMBER,
      originalStoreRemainingBalance: remainingAmount
        ? remainingAmount
        : CONSTANTS.ZERO_TWO_DECIMAL_NUMBER,
    });
  };
  /**
   * PS_PG_56 - PS_PG_81
   * This function handleGridButtons() responsible for hidding or displaying
   * and enabling or disabling the buttons
   */
  const handleGridButtons = async () => {
    const buttonViewObj = JSON.parse(JSON.stringify(buttonNamesObject));
    const buttonEnableObj = JSON.parse(JSON.stringify(buttonNamesObject));

    updateButtonVisibility(buttonViewObj);
    updateButtonEnableStatus(buttonEnableObj);

    setButtonView(buttonViewObj);
    setButtonEnable(buttonEnableObj);
  };

  const updateButtonVisibility = (buttonViewObj: ButtonNameObject) => {
    const conditions = [
      { key: CONSTANTS.SET_TO_DEFAULT, condition: isSetToDefaultVisible() },
      {
        key: CONSTANTS.ADJUST_CHARGE_OFF,
        condition: isAdjustChargeOffVisible(),
      },
      {
        key: CONSTANTS.ADJUST_RENTAL_ITEM_SALE,
        condition: isAdjustRentalItemSaleVisible(),
      },
      { key: CONSTANTS.SUSPENSE, condition: isSuspenseVisible() },
      { key: CONSTANTS.FREE_TIME, condition: isFreeTimeVisible() },
      {
        key: CONSTANTS.ADJUST_DUE_DATE_SMALL_CASE,
        condition: isAdjustDueDateVisible(),
      },
      { key: CONSTANTS.ADJUST_NSF, condition: isAdjustNSFVisible() },
      { key: CONSTANTS.ADJUST_CCCB, condition: isAdjustCCCBVisible() },
      { key: CONSTANTS.EPO_SMALL_CASE, condition: isEPOVisible() },
      { key: CONSTANTS.AUTOPAY, condition: isAutopayVisible() },
      {
        key: CONSTANTS.REMOVE_AMOUNT_DUE,
        condition: isRemoveAmountDueVisible(),
      },
      { key: CONSTANTS.RETENTION, condition: isRetentionVisible() },
      { key: CONSTANTS.RACEXCHANGE, condition: isRACExchangeVisible() },
    ];

    conditions.forEach(({ key, condition }) => {
      if (condition) buttonViewObj[key] = true;
    });
  };

  const updateButtonEnableStatus = (buttonEnableObj: ButtonNameObject) => {
    const conditions = [
      { key: CONSTANTS.SET_TO_DEFAULT, condition: isSetToDefaultEnabled() },
      // {
      //   key: CONSTANTS.ADJUST_CHARGE_OFF,
      //   condition: isAdjustChargeOffEnabled(),
      // },
      { key: CONSTANTS.SUSPENSE, condition: isSuspenseEnabled() },
      { key: CONSTANTS.FREE_TIME, condition: isFreeTimeEnabled() },
      {
        key: CONSTANTS.ADJUST_DUE_DATE_SMALL_CASE,
        condition: isAdjustDueDateEnabled(),
      },
      { key: CONSTANTS.EPO_SMALL_CASE, condition: isEPOEnabled() },
      { key: CONSTANTS.AUTOPAY, condition: isAutopayEnabled() },
      {
        key: CONSTANTS.REMOVE_AMOUNT_DUE,
        condition: isRemoveAmountDueEnabled(),
      },
      { key: CONSTANTS.ACCEPT_EPO, condition: isAcceptEPOEnabled() },
    ];

    conditions.forEach(({ key, condition }) => {
      if (condition) buttonEnableObj[key] = true;
    });
  };

  const isSetToDefaultVisible = () =>
    (featureFlags.AdjustDue === CONSTANTS.ONE_STRING ||
      featureFlags.EditSchedule === CONSTANTS.ONE_STRING ||
      featureFlags.AcceptEPO === CONSTANTS.ONE_STRING) &&
    agreementDetailsClone[0].agreementId != CONSTANTS.EMPTY_STRING &&
    agreementDetailsClone.length > CONSTANTS.ZERO_NUMBER;

  const isAdjustChargeOffVisible = () =>
    agreementsTypeObject.chargeOffAgreeement.length > CONSTANTS.ZERO_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isAdjustRentalItemSaleVisible = () =>
    agreementsTypeObject.rentalItemSaleAgreement.length >
      CONSTANTS.ZERO_NUMBER &&
    selectedAgreement.length == CONSTANTS.ONE_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isSuspenseVisible = () =>
    featureFlags.AddApplyTransferSuspense === CONSTANTS.ONE_STRING &&
    selectedAgreement.length > CONSTANTS.ZERO_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isFreeTimeVisible = () =>
    (featureFlags.RegularFreeTime === CONSTANTS.ONE_STRING ||
      featureFlags.ServiceFreeTime === CONSTANTS.ONE_STRING ||
      featureFlags.PromoFreeTime === CONSTANTS.ONE_STRING ||
      featureFlags.RetentionExtensionFreeTime == CONSTANTS.ONE_STRING) &&
    selectedAgreement.length > CONSTANTS.ZERO_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isAdjustDueDateVisible = () =>
    featureFlags.AdjustDue == CONSTANTS.ONE_STRING &&
    selectedAgreement.length > CONSTANTS.ZERO_NUMBER;

  const isAdjustNSFVisible = () =>
    agreementsTypeObject.nsfCheckAgreement.length > CONSTANTS.ZERO_NUMBER &&
    selectedAgreement.length == CONSTANTS.ONE_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isAdjustCCCBVisible = () =>
    agreementsTypeObject.cccbAgreement.length > CONSTANTS.ZERO_NUMBER &&
    selectedAgreement.length == CONSTANTS.ONE_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isEPOVisible = () =>
    featureFlags.AcceptEPO == CONSTANTS.ONE_STRING &&
    selectedAgreement.length > CONSTANTS.ZERO_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isAutopayVisible = () =>
    selectedAgreement.length > CONSTANTS.ZERO_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isRemoveAmountDueVisible = () =>
    featureFlags.RemoveAmountDue == CONSTANTS.ONE_STRING &&
    selectedAgreement.length > CONSTANTS.ZERO_NUMBER &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isRetentionVisible = () =>
    featureFlags.CustomerPaymentPageCustomerRetention == CONSTANTS.ONE_STRING &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isRACExchangeVisible = () =>
    featureFlags.AgreementExchange == CONSTANTS.ONE_STRING &&
    customerOrderPayment == CONSTANTS.EMPTY_STRING;

  const isSetToDefaultEnabled = () =>
    agreementsTypeObject.transferAgreement.length === CONSTANTS.ZERO_NUMBER;

  const isSuspenseEnabled = () =>
    agreementsTypeObject.transferAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.clubAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.notePaymentAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.promoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.initialPaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.differentStoreAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sacPifEpoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.nsfCheckAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.cccbAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.otherIncomeAgreements.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.pifNotePaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sacPifEpoRentAgreements.length ==
      CONSTANTS.ZERO_NUMBER;

  const isFreeTimeEnabled = () =>
    agreementsTypeObject.transferAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.initialPaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.zeroPaymentAgreemnets.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.differentStoreAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.promoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.nsfCheckAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.cccbAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.clubAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sacPifEpoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sameScheduleAgreements.length ==
      selectedAgreement.length &&
    agreementsTypeObject.returnAgreements.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.otherIncomeAgreements.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.pifNotePaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sacPifEpoRentAgreements.length ==
      CONSTANTS.ZERO_NUMBER &&
    (agreementsTypeObject.ldwAgreements.length == CONSTANTS.ZERO_NUMBER ||
      agreementsTypeObject.ldwAgreements.length == selectedAgreement.length) &&
    (agreementsTypeObject.policyAgreements.length == CONSTANTS.ZERO_NUMBER ||
      agreementsTypeObject.policyAgreements.length == selectedAgreement.length);

  const isAdjustDueDateEnabled = () =>
    agreementsTypeObject.transferAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sacPifEpoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.promoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.initialPaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.differentStoreAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sameScheduleAgreements.length ==
      selectedAgreement.length &&
    agreementsTypeObject.zeroPaymentAgreemnets.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.nsfCheckAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.cccbAgreement.length == CONSTANTS.ZERO_NUMBER &&
    freeTimeEdited == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.returnAgreements.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.otherIncomeAgreements.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.pifNotePaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.sacPifEpoRentAgreements.length ==
      CONSTANTS.ZERO_NUMBER;

  const isEPOEnabled = () =>
    agreementsTypeObject.clubAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.initialPaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.transferAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.eppAgreements.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.promoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.zeroPaymentAgreemnets.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.differentStoreAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    selectedAgreement.length == CONSTANTS.ONE_NUMBER &&
    agreementsTypeObject.nsfCheckAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.cccbAgreement.length == CONSTANTS.ZERO_NUMBER &&
    freeTimeEdited == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.returnAgreements.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.otherIncomeAgreements.length == CONSTANTS.ZERO_NUMBER;

  const isAutopayEnabled = () =>
    agreementsTypeObject.initialPaymentAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.promoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    sameSchedule === selectedAgreement.length &&
    agreementsTypeObject.differentStoreAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    (agreementsTypeObject.autopayAgreements.length == CONSTANTS.ZERO_NUMBER ||
      agreementsTypeObject.autopayAgreements.length ===
        selectedAgreement.length) &&
    agreementsTypeObject.nsfCheckAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.cccbAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.otherIncomeAgreements.length ==
      CONSTANTS.ZERO_NUMBER &&
    !(
      (clubAgreementDetail?.agreementStatus == CONSTANTS.PENDING ||
        clubAgreementDetail?.agreementStatus == CONSTANTS.CLUB_PENDING) &&
      clubAgreementDetail.selected
    );

  const isRemoveAmountDueEnabled = () =>
    agreementsTypeObject.differentStoreAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.chargeOffAgreeement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.nsfCheckAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.cccbAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.rentalItemSaleAgreement.length ==
      CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.backRentAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.returnAgreements.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.promoAgreement.length == CONSTANTS.ZERO_NUMBER &&
    agreementsTypeObject.otherIncomeAgreements.length == CONSTANTS.ZERO_NUMBER;

  const isAcceptEPOEnabled = () =>
    agreementsTypeObject.serviceAgreements.length == CONSTANTS.ZERO_NUMBER &&
    freeTimeEdited == CONSTANTS.ZERO_NUMBER;

  /**
   * PS_PG_83 - PS_PG_86
   * This function renderBindGridHead() binds the header for payment grid
   * @returns
   */
  const renderBindGridHead = () => (
    <>
      {gridHeaderFields.map((val, index) => {
        return val.display === CONSTANTS.NULL ? (
          <RACTableCell
            className={` ${classes.checkboxwidth} ${classes.font12}`}
            key={index}
          >
            <input
              style={{ marginTop: '7px', marginLeft: '19px' }}
              type="checkbox"
              id="maincheckbox"
              data-testid="maincheckboxId"
              disabled={
                agreementDetailsClone.length == CONSTANTS.ZERO_NUMBER ||
                (agreementDetailsClone.length == CONSTANTS.ONE_NUMBER &&
                  agreementDetailsClone[0].agreementId ==
                    CONSTANTS.EMPTY_STRING) ||
                agreementDetailsClone.length == disabledId.length ||
                agreementId != CONSTANTS.ZERO_STRING ||
                customerOrderPayment != CONSTANTS.EMPTY_STRING ||
                (clubAgreementDetail?.disabled &&
                  clubAgreementDetail?.deactivateClubFlag) ||
                (agreementDetailsClone?.some(
                  (el) => el.isLDWActionPerformed == CONSTANTS.STATUS_NO
                ) &&
                  agreementDetailsBasedOnStoreNumbers[0]?.Club?.some(
                    (el) => el.storeNumber == sessionStoreNumber
                  ))
              }
              checked={
                selectedAgreement.length ===
                agreementDetailsClone.length - exchangeId.length
              }
              onClick={() => agreementChecked()}
            />
          </RACTableCell>
        ) : val.display === CONSTANTS.CURRENT_DAYS_LATE ||
          val.display === CONSTANTS.AMOUNT_DUE ? (
          <RACTableCell key={index} className={`${classes.font12}`}>
            <span style={{ float: 'right' }}>{val.display}</span>
          </RACTableCell>
        ) : val.display === CONSTANTS.ACTION ? (
          <RACTableCell
            key={index}
            className={`${classes.font12} ${classes.Pl25}`}
          >
            {val.display}
          </RACTableCell>
        ) : (
          <RACTableCell key={index} className={`${classes.font12}`}>
            {val.display}
          </RACTableCell>
        );
      })}
    </>
  );
  /**
   * PS_PG_87 - PS_PG_109
   * This function bindGridBody() binds the agreement line
   * handles condition for retention and rac exchange icons
   * @returns
   */
  const bindGridBody = () => {
    return (
      <>
        {agreementDetailsBasedOnStoreNumbers?.length > CONSTANTS.ZERO_NUMBER &&
        agreementDetailsClone[0].agreementId != CONSTANTS.EMPTY_STRING ? (
          bindAgreementDetails()
        ) : (
          <RACTableRow
            className={classes.hiddenRow}
            key="0"
            data-testid="paymentGrid-No Records"
          >
            <RACTableCell colSpan={9} className={classes.textcenter}>
              No Records Found
            </RACTableCell>
          </RACTableRow>
        )}
      </>
    );
  };

  const bindAgreementDetails = () => {
    // const returnPeriod =
    //   featureFlags?.AgreementExchangeReturnPeriod ?? CONSTANTS.SEVEN_NUMBER;
    const exchangePeriod =
      featureFlags?.AgreementExchangePeriod ?? CONSTANTS.SIX_NUMBER;
    return agreementDetailsBasedOnStoreNumbers?.map(
      (item: AgreementDetailsBasedOnStore, index: number) => {
        let city = CONSTANTS.EMPTY_STRING;
        let state = CONSTANTS.EMPTY_STRING;
        const storeNumber = Object.keys(item)[0];
        const agreementDatas = item[storeNumber];
        storeInfo?.storeDetails?.forEach((el: StoreDetails) => {
          if (el?.storeNumber == storeNumber) {
            city = el?.city;
            state = el?.stateProvinceName;
          }
        });
        return (
          //Need to check club - code review
          <React.Fragment key={`${index}-agr-${storeNumber}-${city}-${state}`}>
            {storeNumber != CONSTANTS.CLUB && (
              <RACTableRow
                className={` ${classes.rowColor} ${classes.font12}`}
                data-testid={`paymentGrid-tableBody-tableRow-${index}`}
              >
                <RACTableCell
                  className={` ${classes.paymentStore}`}
                  data-testid={`paymentGrid-tableBody-storeNumber-${index}`}
                >
                  {storeNumber ?? CONSTANTS.EMPTY_STRING}
                </RACTableCell>

                <RACTableCell
                  colSpan={9}
                  className={` ${classes.paymentStore}`}
                >
                  {city && state
                    ? CONSTANTS.HYPHEN_WITH_SPACE +
                      city +
                      CONSTANTS.COMMA_WITH_SPACE +
                      state
                    : CONSTANTS.EMPTY_STRING}
                </RACTableCell>
              </RACTableRow>
            )}

            {agreementDatas.map(
              (eachAgreement: AgreementDetails, index: number) => {
                const retentionDisplay =
                  !CONSTANTS.AGREEMENT_TYPE_ARRAY.includes(
                    eachAgreement.agreementType
                  ) &&
                  !eachAgreement.isEpp &&
                  eachAgreement.storeNumber == sessionStoreNumber &&
                  eachAgreement.numberOfPaymentsMade != CONSTANTS.ZERO_NUMBER;
                const currentDate = moment().format(CONSTANTS.YYYY_MM_DD);
                const exchangePeriodDate = moment(
                  eachAgreement.openDate,
                  CONSTANTS.YYYY_MM_DD
                )
                  .add(exchangePeriod, 'months')
                  .format(CONSTANTS.YYYY_MM_DD);
                const racExchangeDisplay =
                  !CONSTANTS.AGREEMENT_TYPE_ARRAY.includes(
                    eachAgreement.agreementType
                  ) &&
                  !eachAgreement.isEpp &&
                  eachAgreement.agreementStatus == CONSTANTS.ACTIVE &&
                  eachAgreement.agreementType ==
                    CONSTANTS.RENTAL_AGREEMENT_RTO &&
                  eachAgreement.storeNumber === sessionStoreNumber &&
                  eachAgreement.numberOfPaymentsMade != CONSTANTS.ZERO_NUMBER &&
                  moment(eachAgreement.currentDueDate).isSameOrAfter(
                    currentDate
                  ) &&
                  moment(currentDate).isSameOrBefore(exchangePeriodDate)
                    ? true
                    : false;

                return (
                  <>
                    <RACTableRow
                      className={` ${classes.rowColor} ${classes.font12}`}
                      key={`${storeNumber}` + `${index}`}
                    >
                      <RACTableCell
                        className={` ${classes.checkboxwidth} ${classes.font12}`}
                      >
                        <input
                          style={{ marginTop: '6px', marginLeft: '19px' }}
                          type="checkbox"
                          name="grid"
                          id={'chk' + index}
                          data-testid={'subcheckbox' + index}
                          checked={
                            selectedId.includes(
                              eachAgreement.agreementNumber
                            ) ||
                            selectedId.includes(
                              String(eachAgreement?.miscCounters)
                            ) ||
                            customerOrderPayment != CONSTANTS.EMPTY_STRING
                          }
                          onClick={() =>
                            agreementChecked(
                              eachAgreement.agreementNumber == CONSTANTS.ADJUST
                                ? String(eachAgreement.miscCounters)
                                : eachAgreement.agreementNumber
                            )
                          }
                          disabled={
                            eachAgreement.disabled ||
                            customerOrderPayment != CONSTANTS.EMPTY_STRING ||
                            otherActionLoader
                          }
                        />
                      </RACTableCell>
                      <RACTableCell className={`${classes.font12}`}>
                        {!CONSTANTS.NO_REDIRECTION_AGR_TYPE_ARRAY.includes(
                          eachAgreement.agreementType
                        ) ? (
                          <span
                            data-testid={`agreement#-${index}`}
                            className={
                              eachAgreement.daysLate == CONSTANTS.ZERO_NUMBER
                                ? `${classes.fontchange} ${classes.racpadlink} ${classes.racpadlink} ${classes.racpadpaymentsuccess} ${classes.racpadagrlink} ${classes.Aghover}`
                                : `${classes.fontchange} ${classes.racpadlink} ${classes.racpadagrlink} ${classes.racpadpaymentfails} ${classes.Aghover}`
                            }
                            onClick={() =>
                              eachAgreement.agreementType ===
                              CONSTANTS.BACK_RENT
                                ? redirectionToBackRent()
                                : CONSTANTS.NON_INVENTORY_AGREEMENT_TYPE_ARRAY.includes(
                                    eachAgreement.agreementType
                                  )
                                ? redirectionToCashSale(
                                    eachAgreement?.miscCounters
                                  )
                                : eachAgreement.agreementType ===
                                  CONSTANTS.RENTAL_ITEM_SALE
                                ? redirectionToInventory(
                                    eachAgreement.agreementNumber
                                  )
                                : redirectionToAgreement(eachAgreement)
                            }
                          >
                            {eachAgreement.agreementNumber}
                            {eachAgreement.isAutopayEnroll ? (
                              <span
                                className={classes.badgeContainer}
                                title={`Card ending with ${eachAgreement.cardLastFour}`}
                              >
                                AP
                              </span>
                            ) : (
                              <></>
                            )}
                          </span>
                        ) : (
                          <span
                            className={
                              eachAgreement.daysLate == CONSTANTS.ZERO_NUMBER
                                ? `${classes.fontchange} ${classes.racpadlink} ${classes.racpadlink} ${classes.racpadpaymentsuccess} ${classes.racpadagrlink} ${classes.nonLinkText}`
                                : `${classes.fontchange} ${classes.racpadlink} ${classes.racpadagrlink} ${classes.racpadpaymentfails} ${classes.nonLinkText}`
                            }
                          >
                            {eachAgreement.agreementNumber}
                            {eachAgreement.isAutopayEnroll ? (
                              <span
                                className={classes.badgeContainer}
                                title={`Card ending with ${eachAgreement.cardLastFour}`}
                              >
                                AP
                              </span>
                            ) : (
                              <></>
                            )}
                          </span>
                        )}
                      </RACTableCell>
                      <RACTableCell
                        className={`${classes.font12}`}
                        data-testid={`paymentGrid-agreement-type-${eachAgreement.agreementType}-${index}`}
                      >
                        {eachAgreement.agreementType ==
                        CONSTANTS.RENTAL_AGREEMENT_RTO
                          ? CONSTANTS.RENT
                          : eachAgreement.agreementType}
                      </RACTableCell>
                      <RACTableCell
                        className={`${classes.font12}`}
                        title={eachAgreement.agreementDesc}
                        data-testid={'maingridtooltipdisplay' + index}
                      >
                        {storeNumber != CONSTANTS.CLUB
                          ? ellipsisFormat(eachAgreement.agreementDesc, 20)
                          : eachAgreement.agreementDesc}
                      </RACTableCell>
                      <RACTableCell className={`${classes.font12}`}>
                        {schedule(eachAgreement.agreementSchedule)}
                      </RACTableCell>
                      <RACTableCell className={`${classes.font12}`}>
                        {dateFormat(
                          eachAgreement.currentDueDate,
                          CONSTANTS.MM_DD_YYYY_DDD
                        )}
                      </RACTableCell>
                      <RACTableCell
                        className={
                          eachAgreement.isExchanged ||
                          eachAgreement.isAdjustDuedateEdit
                            ? `${classes.modifiedGrid} ${classes.font12}`
                            : `${classes.font12}`
                        }
                        title={
                          eachAgreement.isExchanged
                            ? CONSTANTS.EXCHANGE_IN_PROGRESS
                            : CONSTANTS.EMPTY_STRING
                        }
                      >
                        {eachAgreement.isExchanged
                          ? ellipsisFormat(CONSTANTS.EXCHANGE_IN_PROGRESS, 15)
                          : !CONSTANTS.NEXT_DUE_DATE_TYPE_ARRAY.includes(
                              eachAgreement.nextDueDate
                            ) &&
                            !CONSTANTS.NOTE_AGR_NEXT_DUE_DATE_ARRAY.includes(
                              eachAgreement.nextDueDate
                            )
                          ? dateFormat(
                              eachAgreement.nextDueDate,
                              CONSTANTS.MM_DD_YYYY_DDD
                            )
                          : dueDateFormat(eachAgreement.nextDueDate)}
                      </RACTableCell>
                      <RACTableCell
                        className={`${classes.textend} ${classes.font12}`}
                      >
                        {eachAgreement.daysLate}
                      </RACTableCell>
                      <RACTableCell
                        data-testid={`dollar-${index}`}
                        className={`${classes.textend} ${classes.font12} ${classes.amounttxtstyle}`}
                      >
                        <span>$</span>
                        {calculateTotalDueAmount(
                          eachAgreement,
                          customerOrderPayment
                        )}
                      </RACTableCell>

                      {customerOrderPayment == CONSTANTS.EMPTY_STRING && (
                        <RACTableCell
                          role="actionIcons"
                          className={
                            eachAgreement.isExchanged && buttonView.racExchange
                              ? `${classesForExchange.textAlignCenter} ${classesForExchange.noWrap} ${classesForExchange.wrapContentSA} ${classesForExchange.positionRelative} ${classesForExchange.pointerEvents}`
                              : `${classesForExchange.textAlignCenter} ${classesForExchange.noWrap} ${classesForExchange.wrapContentSA} ${classesForExchange.positionRelative}`
                          }
                        >
                          {eachAgreement.isExchanged &&
                            buttonView.racExchange && (
                              <Grid
                                className={`${classesForExchange.overlay}`}
                              ></Grid>
                            )}
                          {buttonView.racExchange && racExchangeDisplay && (
                            <>
                              <RACTooltip
                                placement={
                                  !eachAgreement.deliveryFlag
                                    ? 'top'
                                    : 'top-start'
                                }
                                title={
                                  <span
                                    className={`${classesForExchange.fontWidth}`}
                                  >
                                    {!eachAgreement.deliveryFlag
                                      ? CONSTANTS.EXCHANGE_DELIVERY_INITIATION_TOOLTIP
                                      : CONSTANTS.EXCHANGE}
                                  </span>
                                }
                                classes={{
                                  tooltip: classesForExchange.tooltipcss1,
                                  arrow: classesForExchange.tooltipStyling1,
                                }}
                              >
                                <Grid
                                  className={
                                    !eachAgreement.deliveryFlag
                                      ? `${classesForExchange.positionRelative}`
                                      : CONSTANTS.EMPTY_STRING
                                  }
                                >
                                  {!eachAgreement.deliveryFlag && (
                                    <Grid
                                      className={`${classesForExchange.overlay2}`}
                                    ></Grid>
                                  )}
                                  <ExchangeIcon
                                    data-testid={`exchangeIcon-${index}`}
                                    role="exchangeIcon"
                                    className={
                                      !racExchangeDisplay
                                        ? `${classesForExchange.iconWidth1} ${classesForExchange.pointer} ${classesForExchange.mr5}`
                                        : `${classesForExchange.iconWidth1} ${classesForExchange.pointer}`
                                    }
                                    onClick={() => {
                                      setSelectedRetentionOrExchangeId({
                                        agreementId: eachAgreement.agreementId,
                                        agreementNumber:
                                          eachAgreement.agreementNumber,
                                      });
                                      setAuthDetails({
                                        ...authDetails,
                                        racExchangeAuth: true,
                                      });
                                    }}
                                  />
                                </Grid>
                              </RACTooltip>
                            </>
                          )}

                          {buttonView.retention && retentionDisplay && (
                            <>
                              <RACTooltip
                                placement={'top-start'}
                                title={
                                  <span
                                    className={`${classesForExchange.fontWidth}`}
                                  >
                                    Retention
                                  </span>
                                }
                                classes={{
                                  tooltip: classesForExchange.tooltipcss1,
                                  arrow: classesForExchange.tooltipStyling1,
                                }}
                              >
                                <RetentionIcon
                                  data-testid={`retention-icon-${index}`}
                                  className={`${classesForExchange.iconWidth2} ${classesForExchange.pointer}`}
                                  onClick={() => {
                                    setSelectedRetentionOrExchangeId({
                                      agreementId: eachAgreement.agreementId,
                                      agreementNumber:
                                        eachAgreement.agreementNumber,
                                    });
                                    setAuthDetails({
                                      ...authDetails,
                                      retentionAuth: true,
                                    });
                                  }}
                                />
                              </RACTooltip>
                            </>
                          )}
                          {!(
                            (buttonView.racExchange && racExchangeDisplay) ||
                            (buttonView.retention && retentionDisplay)
                          ) && (
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                              }}
                            >
                              -
                            </span>
                          )}
                        </RACTableCell>
                      )}

                      <RACTableCell
                        data-bs-toggle="collapse"
                        data-bs-target="#row-1"
                        //className={`${classes.accordiontoggle} ${classes.font12}`}
                        aria-expanded={true}
                      >
                        <img
                          data-testid={`accordian-open-${index}`}
                          className={`${classes.accordionIconGrid} ${classes.me2}`}
                          id={index + 't1'}
                          src={
                            accordionIndex == storeNumber + index
                              ? AccordianOpenIcon
                              : AccordianCloseIcon
                          }
                          onClick={() =>
                            setAccordionIndex((prev) =>
                              prev === storeNumber + index
                                ? CONSTANTS.NULL
                                : storeNumber + index
                            )
                          }
                        />
                      </RACTableCell>
                    </RACTableRow>

                    {accordionIndex == storeNumber + index &&
                      bindAccordionRow(eachAgreement)}
                  </>
                );
              }
            )}
          </React.Fragment>
        );
      }
    );
  };

  const bindAccordionRow = (eachAgreement: AgreementDetails) => {
    const suspAmount =
      Number(eachAgreement.suspenseAmount) +
      Number(eachAgreement.suspenseDetails?.transferSusAdd) -
      Number(eachAgreement.suspenseDetails?.transferSusUse);
    return (
      <>
        <RACTableRow data-testid="gridhidediv">
          <RACTableCell
            colSpan={10}
            className={`${classes.hiddenRow} ${classes.p0}`}
          >
            <Grid
              item
              className={`${classes.backgroundcol} ${classes.floatLeft} ${classes.pb0} ${classes.px5} ${classes.py2} ${classes.w100}`}
              id="row-2"
            >
              <Grid
                item
                className={`${classes.gridAcordianRow} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  SAC Date
                </Box>
                <Box
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {eachAgreement.sacDate == CONSTANTS.HYPHEN ||
                  eachAgreement.agreementType == CONSTANTS.PROMO
                    ? CONSTANTS.HYPHEN
                    : dateFormat(
                        eachAgreement.sacDate,
                        CONSTANTS.MM_DD_YYYY_DDD
                      )}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.gridAcordianRow} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Suspense Amount
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  ${Number(suspAmount).toFixed(2)}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.gridAcordianRow} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  EPO Amount
                </Box>

                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {eachAgreement.isEpp
                    ? CONSTANTS.HYPHEN
                    : CONSTANTS.DOLLAR +
                      Number(eachAgreement.epoAmount).toFixed(2)}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.gridAcordianRow} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  #Late last 10
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {eachAgreement.lateLast10}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.gridAcordianRow} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Promo Free Days
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {eachAgreement.promoFreeDays}
                </Box>
              </Grid>
              <Grid
                item
                className={`${classes.gridAcordianRow} ${classes.floatLeft}`}
                style={{ margin: '0 1%' }}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Autopay
                </Box>

                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  {eachAgreement.isAutopayEnroll == CONSTANTS.NULL ||
                  eachAgreement.cardLastFour == CONSTANTS.NULL
                    ? CONSTANTS.HYPHEN
                    : CONSTANTS.CARD_ENDING_WITH + eachAgreement.cardLastFour}
                </Box>
              </Grid>
              {/* RAC2 */}
              <Grid
                item
                className={`${classes.gridAcordianRow} ${classes.floatLeft}`}
              >
                <Box
                  component="span"
                  className={`${classes.paymentAgrListLabel} ${classes.w100} ${classes.floatLeft} ${classes.mb1}`}
                >
                  Rent Paid
                </Box>
                <Box
                  component="span"
                  className={`${classes.paymentAgrListValue} ${classes.w100} ${classes.floatLeft} ${classes.labelnotxtstyle} ${classes.pb2}`}
                >
                  ${Number(eachAgreement.rentPaid).toFixed(2)}
                </Box>
              </Grid>
            </Grid>
          </RACTableCell>
        </RACTableRow>
      </>
    );
  };
  /**
   * PS_PG_110 - PS_PG_123
   * This function agreementChecked() handles check and uncheck
   * @param agreementNumber
   */
  const agreementChecked = (agreementNumber?: string) => {
    const response: AgreementDetails[] = [];
    let allCheck = true;
    if (agreementNumber) {
      agreementDetailsClone.map((eachAgreement: AgreementDetails) => {
        if (
          eachAgreement.agreementNumber == agreementNumber ||
          eachAgreement.miscCounters == Number(agreementNumber)
        )
          response.push({
            ...eachAgreement,
            selected: !eachAgreement.selected,
          });
        else response.push({ ...eachAgreement });
      });
    } else {
      if (
        selectedAgreement.length ===
        agreementDetailsClone.length -
          exchangeId.length -
          (disabledId.length - disableSelectedId.length)
      )
        allCheck = false;
      agreementDetailsClone.map((eachAgreement: AgreementDetails) => {
        if (allCheck && !eachAgreement.disabled)
          response.push({ ...eachAgreement, selected: true });
        else if (!allCheck && eachAgreement.agreementId != agreementId)
          response.push({ ...eachAgreement, selected: false });
        else response.push({ ...eachAgreement });
      });
    }
    setAgreementDetails(response);
    setCustomerInfo({ ...customerInfo, carryRentEnable: false });
    setRenderContext(!renderContext);
  };
  /**
   * PS_PG_124 - PS_PG_125
   * This function setToDefault() triggers while set to default button is clicked
   */
  const setToDefault = async () => {
    setLoaderSetToDefault(true);
    (() => {
      if (agreementDetailsDeepClone.length) {
        setAgreementDetails(agreementDetailsDeepClone);
      } else setAgreementDetails(CONSTANTS.AGREEMENT_DETAILS_INITIAL_ARRAY);
    })();
    setCustomerInfo(customerInfoDeepClone);
    setCappedFreeTimeDetails({
      ...cappedFreeTimeDetails,
      storeRemainingAmount: cappedFreeTimeDetails.originalStoreRemainingBalance,
      amountUsed: CONSTANTS.ZERO_NUMBER,
    });
    setRenderContext(!renderContext);
    setIsSetToDefault(isSetToDefault + CONSTANTS.ONE_NUMBER);
    if (otherIncomeChargeOffDetailsDeepClone?.length > 0)
      setOtheIncomeChargeOffDetails(otherIncomeChargeOffDetailsDeepClone);

    setCommitmentLoad(false);
    setLoaderSetToDefault(false);
  };
  /**
   * PS_PG_126 - PS_PG_131
   * This function handleRemoveAmountDue() triggers while remove amount due is clicked
   * @returns
   */
  const handleRemoveAmountDue = () => {
    setRenderContext(!renderContext);
    setRemoveAmountDue(!removeAmountDue);
    let freeTimeAmount = 0;
    const mappedAgrDetails = agreementDetailsClone.map(
      (eachAgreement: AgreementDetails) => {
        if (eachAgreement.selected) {
          freeTimeAmount += eachAgreement.freeTimeAmountUsed ?? 0;
          return {
            ...eachAgreement,
            totalDueAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            totalTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            lateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            lateFeetax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            totalAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            carriedLateRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            carriedRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            ldwAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            ldwTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            policyAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            policyTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            suspenseDetails: {
              ...eachAgreement.suspenseDetails,
              suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
              suspUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
              // transferSusAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
              // transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            },
            nextDueDate: eachAgreement.currentDueDate,
            agreementType:
              CONSTANTS.ZERO_PAYMENT_CHANGE_TYPE_AS_RENT_ARRAY.includes(
                eachAgreement.agreementType
              )
                ? CONSTANTS.RENTAL_AGREEMENT_RTO
                : eachAgreement.agreementType,
            isZeroPayment: true,
            isFreeTimeEdit: false,
            freeTime: null,
            freeTimeAmountUsed: 0,
            isAdjustDuedateEdit: false,
            waivedLateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            carriedLateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            acceptEpoFlag: 0,
            isAcceptEPODiscounts: {
              noteDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
              epoDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
              pastDueDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
            },
            isExactSus: undefined,
            isEPOSusAmtDue: undefined,
          };
        } else return { ...eachAgreement };
      }
    );
    setAgreementDetails(mappedAgrDetails);
    setCappedFreeTimeDetails({
      ...cappedFreeTimeDetails,
      storeRemainingAmount:
        cappedFreeTimeDetails.storeRemainingAmount + freeTimeAmount,
      amountUsed: Math.max(
        0,
        cappedFreeTimeDetails.amountUsed - freeTimeAmount
      ),
    });
    const customerDetail = JSON.parse(JSON.stringify(customerInfo));
    customerDetail.carryRentEnable = false;
    setCustomerInfo(customerDetail);
  };

  /**
   * PS_PG_138 - PS_PG_139
   * This function redirectionToBackRent() handles redirection for back rent
   */
  const redirectionToBackRent = () => {
    const data = agreementDetails.filter(
      (x) => x.agreementType == CONSTANTS.BACK_RENT
    );
    const amount = Number(data[0].totalDueAmount) - Number(data[0].totalTax);
    const obj: BackRentEditInfo = {
      backRentOwed: backRentInfo.backRentOwed,
      amount: Number(amount).toFixed(2),
      salesTax: Number(data[0].totalTax).toFixed(2),
      taxRate: backRentInfo.taxRate,
      total: Number(data[0].totalDueAmount).toFixed(2),
      defaultTotal: backRentInfo.defaultTotal,
    };
    setBackRentEditInfo(obj);
    setBackRentPopup(true);
  };

  /**PSC_CO_NO_35 - PSC_CO_NO_36
   * The redirectionChargeoff() method is invoked when the Adjust charge-off button is clicked
   */
  const redirectionChargeoff = () => {
    const editChargeOffArray: ChargeOffDetails[] = [];
    const data: AgreementDetails[] = agreementDetails.filter(
      (x) => x.agreementType == CONSTANTS.RESTITUTION && x.selected
    );
    const otherIncomeArray = JSON.parse(
      JSON.stringify(otheIncomeChargeOffDetails)
    );
    if (data) {
      data.forEach((el: AgreementDetails) => {
        const editArr = otherIncomeArray.find(
          (x: ChargeOffDetails) =>
            Number(x.agreementId) == Number(el.agreementId)
        );
        editArr ? editChargeOffArray.push(editArr) : CONSTANTS.NULL;
      });
    }
    setEditChargeoffDatas(editChargeOffArray);
    setChargeOffPopupFlag(true);
  };

  /**
   * PS_PG_134 - PS_PG_135
   * Handles redirection for agreement page
   * PS_PG_134 - PS_PG_135
   * Handles redirection for agreement page
   */
  const redirectionToAgreement = (eachAgreement: AgreementDetails) => {
    if (
      eachAgreement.agreementType != CONSTANTS.CREDIT_CARD_CHARGE_BACK ||
      eachAgreement.agreementType != CONSTANTS.NSF_CHECK
    ) {
      history.push({
        pathname: `/agreement/info/details/${customerId}/${eachAgreement.agreementId}`,
      });
    }
  };

  /**
   * PS_PG_132 - PS_PG_133
   * Handles redirection for inventory page
   */
  const redirectionToInventory = (agreementNumber: string) => {
    history.push({
      pathname: `/inventory/${agreementNumber}/inventoryinformation`,
    });
  };

  /**
   * PS_PG_136 - PS_PG_137
   * Handles redirection for cashsale
   */
  const redirectionToCashSale = (counter: number | undefined) => {
    const index = agreementDetails.findIndex(
      (el: AgreementDetails) => el.miscCounters == counter
    );
    setNonInventorySaleInfo({ ...nonInventorySaleInfo, editIndex: index });

    if (customerId) {
      if (Number(agreementId) != 0) {
        history.push({
          pathname: `/payment1/cashSale/${customerId}/${agreementId}`,
        });
      } else {
        history.push({ pathname: `/payment1/cashSale/${customerId}` });
      }
    }
  };

  const adjustrentalitemsaleOnclick = () => {
    const index = agreementDetails.findIndex(
      (el: AgreementDetails) =>
        el.otherIncomeType == CONSTANTS.RENTAL_ITEM_SALE && el.selected
    );

    if (customerId) {
      if (Number(agreementId) != 0) {
        history.push({
          pathname: `/payment1/rentalitemsale/${customerId}/${agreementId}`,
          state: {
            editNo: 1,
            editIndex: index,
          },
        });
      } else {
        history.push({
          pathname: `/payment1/rentalitemsale/${customerId}/0`,
          state: {
            editNo: 1,
            editIndex: index,
          },
        });
      }
    }
  };

  /**
   * PS_PG_140 - PS_PG_157
   * Within return, holds html for buttons, grid, popups, authentications
   */
  return (
    <>
      <Card className={`${classes.card} ${classes.floatLeft} ${classes.mb3}`}>
        <Grid container>
          <RACTable
            className={classes.racGrid}
            renderTableHead={renderBindGridHead}
            renderTableContent={bindGridBody}
          />
        </Grid>

        <Grid>
          {buttonView.suspenseOptions && (
            <Grid
              item
              className={`${classes.btngroup} ${classes.pa}`}
              role="group"
            >
              <OutsideClickHandler
                onOutsideClick={() =>
                  setButtonView({ ...buttonView, suspenseOptions: false })
                }
              >
                <List
                  style={{
                    left: '250px',
                    marginBottom: '5px',
                    borderRadius: '5px',
                  }}
                  className={`${internalClasses.customMenuContainer} ${internalClasses.popUp}`}
                  data-testid="UlButtonsId"
                  aria-labelledby="btnGroupDrop1"
                >
                  <ListItem>
                    <a
                      className={`${internalClasses.dropDownSuspEpo}  ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="addsusId"
                      onClick={() =>
                        setModalCardEnable({
                          ...modalCardEnable,
                          addSuspensePopup: true,
                        })
                      }
                      data-bs-target="#addsus"
                    >
                      Add
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      className={`${internalClasses.dropDownSuspEpo} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="usesusId"
                      onClick={() =>
                        setModalCardEnable({
                          ...modalCardEnable,
                          useSuspensePopup: true,
                        })
                      }
                      data-bs-target="#addsus"
                    >
                      Use
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      className={`${internalClasses.dropDownSuspEpo} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="transfersusId"
                      data-bs-target="#addsus"
                      onClick={() =>
                        setModalCardEnable({
                          ...modalCardEnable,
                          transferSuspensePopup: true,
                        })
                      }
                    >
                      Transfer
                    </a>
                  </ListItem>
                </List>
              </OutsideClickHandler>
            </Grid>
          )}
        </Grid>

        <Grid>
          {buttonView.epoOptions && (
            <Grid
              item
              className={`${classes.btngroup} ${classes.pa}`}
              role="group"
            >
              <OutsideClickHandler
                onOutsideClick={() =>
                  setButtonView({ ...buttonView, epoOptions: false })
                }
              >
                <List
                  style={{
                    left: '625px',
                    marginBottom: '5px',
                    borderRadius: '5px',
                  }}
                  className={`${internalClasses.customMenuContainer} ${internalClasses.popUp}`}
                  data-testid="UlButtonsId"
                  aria-labelledby="btnGroupDrop1"
                >
                  <ListItem
                    disabled={
                      agreementsType.serviceAgreements.length >
                      CONSTANTS.ZERO_NUMBER
                        ? true
                        : false
                    }
                    title={
                      agreementsType.serviceAgreements.length >
                      CONSTANTS.ZERO_NUMBER
                        ? CONSTANTS.ITEM_IN_SERVICE_CANNOT_ACCEPT_EPO_TOOLTOP
                        : CONSTANTS.EMPTY_STRING
                    }
                  >
                    <a
                      className={`${internalClasses.dropDownSuspEpo} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="accept-epo-angertag"
                      onClick={() => {
                        if (
                          agreementsType.serviceAgreements.length ==
                          CONSTANTS.ZERO_NUMBER
                        )
                          setModalCardEnable({
                            ...modalCardEnable,
                            acceptEpoPopup: true,
                          });
                      }}
                      data-bs-target="accept-epo-angertag"
                    >
                      Accept EPO
                    </a>
                  </ListItem>
                  <ListItem>
                    <a
                      className={`${internalClasses.dropDownSuspEpo} ${classes.semibold}`}
                      data-bs-toggle="modal"
                      data-testid="schedule-epo-angertag"
                      onClick={() =>
                        setModalCardEnable({
                          ...modalCardEnable,
                          viewEpoScheduleComponent: true,
                        })
                      }
                      data-bs-target="#addsus"
                    >
                      Schedule
                    </a>
                  </ListItem>
                </List>
              </OutsideClickHandler>
            </Grid>
          )}
        </Grid>

        <Grid
          className={`${classesForExchange.customerTextcenter} ${classes.mb4}`}
        >
          {buttonView.setToDefault && (
            <RACButton
              data-testid="set-to-default-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={!buttonEnable.setToDefault}
              onClick={setToDefault}
              loading={loaderSetToDefatult}
            >
              Set To Default
            </RACButton>
          )}

          {buttonView.adjustChargeOff && (
            <RACButton
              data-testid="adjust-Charge-Off-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              onClick={() => redirectionChargeoff()}
            >
              Adjust Charge Off
            </RACButton>
          )}

          {buttonView.adjustRentalItemSale && (
            <RACButton
              data-testid="adjust-rental-item-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              onClick={() => {
                adjustrentalitemsaleOnclick();
              }}
            >
              Adjust Rental Item Sale
            </RACButton>
          )}

          {buttonView.suspense && (
            <RACButton
              data-testid="suspense-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton} ${classes.pr}`}
              color="primary"
              variant="contained"
              onClick={() =>
                setButtonView({ ...buttonView, suspenseOptions: true })
              }
              disabled={!buttonEnable.suspense}
            >
              Suspense
              <span className={internalClasses.dropdowntoggle}></span>
            </RACButton>
          )}

          {buttonView.freeTime && (
            <RACButton
              data-testid="freetime-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={!buttonEnable.freeTime}
              onClick={() =>
                setModalCardEnable({ ...modalCardEnable, freeTimePopup: true })
              }
            >
              Free Time
            </RACButton>
          )}

          {buttonView.adjustDueDate && (
            <RACButton
              data-testid="adjust-due-date-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              disabled={!buttonEnable.adjustDueDate}
              onClick={() => {
                setModalCardEnable({
                  ...modalCardEnable,
                  adjustDueDatePopup: true,
                });
              }}
            >
              {selectedAgreement?.length == CONSTANTS.ONE_NUMBER
                ? CONSTANTS.ADJUST_DUE_DATE
                : CONSTANTS.ADJUST_ALL_DUE_DATE}
            </RACButton>
          )}

          {buttonView.adjustNSF && (
            <RACButton
              data-testid="adjust-NSF-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              onClick={() => setAuthDetails({ ...authDetails, nsfAuth: true })}
            >
              Adjust NSF
            </RACButton>
          )}

          {buttonView.adjustCCCB && (
            <RACButton
              data-testid="adjust-CCCB-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              onClick={() => setAuthDetails({ ...authDetails, cccbAuth: true })}
            >
              Adjust CCCB
            </RACButton>
          )}

          {buttonView.epo && (
            <RACButton
              data-testid="epo-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              onClick={() => setButtonView({ ...buttonView, epoOptions: true })}
              disabled={!buttonEnable.epo}
            >
              EPO
            </RACButton>
          )}

          {buttonView.autopay && (
            <span
              title={
                !buttonEnable.autopay
                  ? CONSTANTS.AUTOPAY_VALID_AGR_TOOLTIP
                  : CONSTANTS.EMPTY_STRING
              }
            >
              <RACButton
                //type='button'
                className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
                data-bs-toggle="modal"
                data-testid="autoPay-btn"
                color="primary"
                variant="contained"
                data-bs-target="#autopayconsent"
                onClick={() => setAutopayPopup(true)}
                disabled={!buttonEnable.autopay}
              >
                {agreementsType?.autopayAgreements?.length ==
                selectedAgreement.length
                  ? CONSTANTS.UN_ENROLL_AUTOPAY
                  : CONSTANTS.ENROLL_AUTOPAY}
              </RACButton>
            </span>
          )}

          {buttonView.removeAmountDue && (
            <RACButton
              data-testid="remove-amount-due-btn"
              className={`${classes.ms2} ${classes.mt1} ${classes.epoButton}`}
              color="primary"
              variant="contained"
              data-bs-toggle="modal"
              data-bs-target="#autopayconsent"
              disabled={!buttonEnable.removeAmountDue}
              onClick={() => handleRemoveAmountDue()}
            >
              Remove Amount Due
            </RACButton>
          )}
        </Grid>
      </Card>

      <Grid>
        {modalCardEnable.freeTimePopup && <HandleFreeTime />}

        {modalCardEnable.addSuspensePopup && (
          <RACModalCard
            isOpen={modalCardEnable.addSuspensePopup}
            closeIcon={true}
            borderRadius={`${classes.boderRadius25}`}
            onClose={() =>
              setModalCardEnable({
                ...modalCardEnable,
                addSuspensePopup: false,
              })
            }
            maxWidth={'md'}
            title={'Add Suspense'}
          >
            <AddSuspenseComponent />
          </RACModalCard>
        )}

        {modalCardEnable.useSuspensePopup && (
          <RACModalCard
            isOpen={modalCardEnable.useSuspensePopup}
            closeIcon={true}
            borderRadius={`${classes.boderRadius25}`}
            onClose={() =>
              setModalCardEnable({
                ...modalCardEnable,
                useSuspensePopup: false,
              })
            }
            maxWidth={'md'}
            title={'Use Suspense'}
          >
            <UseSuspenseComponent />
          </RACModalCard>
        )}

        {modalCardEnable.transferSuspensePopup && <TransferSuspenseComponent />}

        {modalCardEnable.CCCBPopup && (
          <RACModalCard
            isOpen={modalCardEnable.CCCBPopup}
            closeIcon={true}
            borderRadius={`${classes.boderRadius25}`}
            onClose={() =>
              setModalCardEnable({ ...modalCardEnable, CCCBPopup: false })
            }
            maxWidth={'sm'}
            title={'Adjust Credit Card Charge Back Repayment'}
          >
            <CCCBComponent />
          </RACModalCard>
        )}

        {modalCardEnable.NSFPopup && (
          <RACModalCard
            isOpen={modalCardEnable.NSFPopup}
            maxWidth="sm"
            title="Adjust NSF Check Payment"
            TitleVariantClassName={classes.popUpFont}
            borderRadius={`${classes.boderRadius25}`}
            onClose={() =>
              setModalCardEnable({ ...modalCardEnable, NSFPopup: false })
            }
            closeIcon={true}
          >
            <NSFComponent />
          </RACModalCard>
        )}

        {modalCardEnable.adjustDueDatePopup && <HandleAdjustDueDate />}

        {autopayPopup && (
          <RACModalCard
            isOpen={autopayPopup}
            maxWidth="sm"
            onClose={() => setAutopayPopup(false)}
            closeIcon={true}
          >
            <Autopay
              cardPopup={autopayPopup}
              setCardPopup={(res) => setAutopayPopup(res)}
              unEnrollAutopay={
                agreementsType?.autopayAgreements?.length >
                CONSTANTS.ZERO_NUMBER
                  ? true
                  : false
              }
              manageCard={false}
              racExchange={false} //true for racExchange
            ></Autopay>
          </RACModalCard>
        )}

        {modalCardEnable.acceptEpoPopup && (
          <AcceptEpo
            isOpen={modalCardEnable.acceptEpoPopup}
            selectedAgreement={selectedAgreement}
            onClose={() =>
              setModalCardEnable({ ...modalCardEnable, acceptEpoPopup: false })
            }
          />
        )}

        {modalCardEnable.retentionPopup && (
          <RetentionComponent
            agreementDetails={selectedRetentionOrExchangeId}
          />
        )}

        {modalCardEnable.racExchangeComponent && (
          <RacExchangePopup agreementDetails={selectedRetentionOrExchangeId} />
        )}

        {modalCardEnable.viewEpoScheduleComponent && (
          <EpoSchedule
            agreementId={
              selectedAgreement.length
                ? selectedAgreement[0].agreementId
                : CONSTANTS.ZERO_STRING
            }
            coCustomerProps={customerId}
          />
        )}
      </Grid>

      <Grid>
        {authDetails.cccbAuth && (
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setAuthDetails({ ...authDetails, cccbAuth: false });
            }}
            setTwoFactorCompleted={() => {
              setAuthDetails({ ...authDetails, cccbAuth: false });
              setModalCardEnable({ ...modalCardEnable, CCCBPopup: true });
            }}
            moduleName={CONSTANTS.SLA_MODULE_NAME_CCCB}
            CurrentRole={coWorkerDetails.role}
          ></SecondFactor>
        )}

        {authDetails.nsfAuth && (
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setAuthDetails({ ...authDetails, nsfAuth: false });
            }}
            setTwoFactorCompleted={() => {
              setAuthDetails({ ...authDetails, nsfAuth: false });
              setModalCardEnable({ ...modalCardEnable, NSFPopup: true });
            }}
            moduleName={CONSTANTS.SLA_MODULE_NAME_NSF}
            CurrentRole={coWorkerDetails.role}
          ></SecondFactor>
        )}

        {authDetails.racExchangeAuth && (
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setAuthDetails({ ...authDetails, racExchangeAuth: false });
            }}
            setTwoFactorCompleted={() => {
              setAuthDetails({ ...authDetails, racExchangeAuth: false });
              setModalCardEnable({
                ...modalCardEnable,
                racExchangeComponent: true,
              });
            }}
            moduleName={CONSTANTS.SLA_MODULE_NAME_RACEXCHANGE}
            CurrentRole={coWorkerDetails.role}
          ></SecondFactor>
        )}

        {authDetails.retentionAuth && (
          <SecondFactor
            setTwoFactorCancelClick={() => {
              setAuthDetails({ ...authDetails, retentionAuth: false });
            }}
            setTwoFactorCompleted={() => {
              setAuthDetails({ ...authDetails, retentionAuth: false });
              setModalCardEnable({ ...modalCardEnable, retentionPopup: true });
            }}
            moduleName={CONSTANTS.SLA_MODULE_NAME_RETENTION}
            CurrentRole={coWorkerDetails.role}
          ></SecondFactor>
        )}
      </Grid>

      {backRentPopup && (
        <BackRentComponent
          data-testid="Back-rent-Component"
          backRentEditInfo={backRentEditInfo}
          isOpen={backRentPopup}
          onClose={(res: boolean) => setBackRentPopup(res)}
          customerId={customerId}
        />
      )}

      {chargeOffPopupFlag && (
        <ChargeOffComponent
          isOpen={chargeOffPopupFlag}
          customerId={customerId}
          editChargeoffDatas={editChargeoffDatas}
          isClose={(res: boolean) => setChargeOffPopupFlag(res)}
        />
      )}
    </>
  );
}
