/*eslint-disable*/
import { useContext } from 'react';
import { customerAccountPayloadFormation, tenderDetailsPayloadFormation, suspensePayloadFormation, transferSuspensePayloadFormation, agreementPayloadFormation, clubPayloadFormation, generateMiscPaymentPayload } from '../../utils/utils';
import CONSTANTS from '../constants/constant';
import { PlaceOrderDetails, CardDetailsEntity, AgreementPaymentEntity, TenderDetailsEntity, TransferSuspenseEntity, ClubPaymentEntity, MiscPaymentEntity } from '../interface/acceptPaymentInterface';
import { CustomerAccountPayloadInterface } from '../interface/addCheckInterface';
import { AgreementDetails, CardDetailsInterface, Customer } from '../interface/paymentInfoInterface';
import { agreementContext } from '../context/PaymentInformationContext';
import { useParams } from 'react-router-dom';

interface exchangeAgreementTransferSuspense {
    agreementId: string;
    balance: string;
}
export const CreatePaymentInfoForPayload = async (agreements: AgreementDetails[], processOrderData?: PlaceOrderDetails, key?: string, isCardOnFile?: boolean, paymentInfo?) => {
    debugger;
    const {
        customerId,
        agreementDetails,
        customerInfo,
        multiStoreSelected,
        featureFlags,
        paymentOrigin,
        coWorkerDetails,
        storeInfo,
        exceededFreeTime,
        cappedFreeTimeDetails
    } = paymentInfo;
    const storeNumber = String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER));
    let customerAccountPayload: CustomerAccountPayloadInterface[] = [];
    let cardDetailsPayload: CardDetailsInterface[] = [];
    // let agreementPaymentPayload: AgreementPaymentEntity[];

    customerAccountPayload = customerAccountPayloadFormation(customerInfo);
    const tenderDetailsPayload: TenderDetailsEntity[] = tenderDetailsPayloadFormation(customerInfo);
    const selectedNonClubAgreements: AgreementDetails[] = currentStoreAgreements(agreements, CONSTANTS.AGR, true, key ?? storeNumber,false);
    const currentStoreAgreement: AgreementDetails[] =  currentStoreAgreements(agreementDetails, CONSTANTS.AGR, true, key ?? storeNumber,true);
    const selectedClub: AgreementDetails[] = currentStoreAgreements(agreements, CONSTANTS.CLUB, true, key ?? storeNumber);
    const suspenseArray: CustomerAccountPayloadInterface[] = suspensePayloadFormation(selectedNonClubAgreements);
    customerAccountPayload = [...customerAccountPayload, ...suspenseArray];
    const exchangeAgreementSuspenseTransfer = exchangeAgreementTransferSuspenseFormation(selectedNonClubAgreements, agreementDetails);
    let transferSuspensePayload: TransferSuspenseEntity[] = transferSuspensePayloadFormation(currentStoreAgreement);
    transferSuspensePayload = [...transferSuspensePayload, ...exchangeAgreementSuspenseTransfer];
    const agreementPaymentPayload: AgreementPaymentEntity[] = agreementPayloadFormation(selectedNonClubAgreements);
    const clubPayload: ClubPaymentEntity[] = clubPayloadFormation(selectedClub);
    const otherIncomeAgreements: AgreementDetails[] = currentStoreAgreements(agreements, CONSTANTS.OTHER, true);
    const miscPaymentPayload: MiscPaymentEntity[] = await generateMiscPaymentPayload(otherIncomeAgreements, customerInfo, storeInfo);
    cardDetailsPayload = createCardDetailsPayload(agreements, customerInfo, key, multiStoreSelected,paymentOrigin);

    console.log('inside if AcceptUtil',exceededFreeTime,cappedFreeTimeDetails);
    const paymentInfoObject = {
        storeNumber: storeNumber,
        customerId: customerId,
        exceededFTAmount: exceededFreeTime != undefined && exceededFreeTime > 0 ? Number(Number(exceededFreeTime).toFixed(2)) : Number(cappedFreeTimeDetails?.amountUsed) > 0 ? Number(Number(cappedFreeTimeDetails?.amountUsed).toFixed(2)) : null,
        paymentOrigin: isCardOnFile?CONSTANTS.IN_STORE_T2P_ORIGIN:paymentOrigin == CONSTANTS.IN_STORE_VALUE ? CONSTANTS.ORIGIN_STORE : CONSTANTS.ORIGIN_PHONE,
        placeOrderDetails: processOrderData,
        clubPayment: clubPayload.length ? clubPayload : undefined,
        agreementPayment: agreementPaymentPayload.length
            ? agreementPaymentPayload
            : undefined,
        transferSuspense: transferSuspensePayload.length
            ? transferSuspensePayload
            : undefined,
        miscPayment:
            miscPaymentPayload.length > CONSTANTS.ZERO_NUMBER ? miscPaymentPayload : undefined,
        paymentMethod: {
            tenderDetails: tenderDetailsPayload.length
                ? tenderDetailsPayload
                : undefined,
            cardDetails:
                cardDetailsPayload?.length > CONSTANTS.ZERO_NUMBER ? cardDetailsPayload : undefined,
            customerAccount: customerAccountPayload.length
                ? customerAccountPayload
                : undefined,
        },
        deactivateClub: agreementDetails.some((x: AgreementDetails) =>
            x.deactivateClubFlag
        )
            ? [customerId]
            : undefined,
    }

    if (isCardOnFile == undefined) {
        return {
            raftApiFlag: String(featureFlags?.RaftApiFlag),
            agreementStoreNumber: key ?? storeNumber,
            paymentInfo: [
                {
                    globalCustomerId: customerInfo.customerDetails[0].globalCustomerId,
                    FirstName: customerInfo.customerDetails[0].firstName,
                    LastName: customerInfo.customerDetails[0].lastName,
                    // storeMerchantId: agreements[0]?.storeMerchantId,
                    coWorkerId: coWorkerDetails.employeeId,
                    ...paymentInfoObject
                },
            ],
        }
    }
    else if (isCardOnFile) {
        return {
            paymentInfo: [
                {
                    isEmailNotify: CONSTANTS.STATUS_YES,
                    isTextNotify: CONSTANTS.STATUS_NO,
                    includeConvenienceFee: paymentOrigin != CONSTANTS.IN_STORE_VALUE?CONSTANTS.STATUS_YES:undefined,
                    ...paymentInfoObject
                },
            ],
        }
    } else {
        return {
            paymentInfo: [
                {
                    isEmailNotify: CONSTANTS.STATUS_YES,
                    optimizedPayment:'Y',
                    amount: Number(customerInfo.amountUsed.remainingAmountDue).toFixed(2),
                    FirstName: customerInfo.customerDetails[0].firstName,
                    LastName: customerInfo.customerDetails[0].lastName,
                    // storeMerchantId: agreements[0].storeMerchantId,
                    coWorkerId: coWorkerDetails.employeeId,//Need to add
                    ...paymentInfoObject
                },
            ],
        }
    }
}


const createCardDetailsPayload = (agreements: AgreementDetails[], customerInfo: Customer, storeNumber: string | undefined, multiStoreSelected: boolean, paymentOrigin: string) => {
    if (multiStoreSelected) {
        let agreementAmountDue = CONSTANTS.ZERO_NUMBER;
        const filteredDataForCard = agreements.filter(el => el.storeNumber === storeNumber);

        filteredDataForCard.forEach(el => {
            const individualAgrTotal = Number(el.totalDueAmount) + Number(el.suspenseDetails.suspAdd) - Number(el.suspenseDetails.suspUse);
            agreementAmountDue += Math.max(individualAgrTotal, 0);
        });
        if(paymentOrigin != CONSTANTS.IN_STORE_VALUE){
            agreementAmountDue=agreementAmountDue + Number(customerInfo.amountUsed.convenienceFee);
        }
        
        if(Number(customerInfo.userEnteredDonationAmount)>0 && storeNumber == String(window.sessionStorage.getItem("storeNumber")))
        {
            agreementAmountDue=agreementAmountDue + Number(customerInfo.userEnteredDonationAmount)
        }

        const cardDetailsPayload: CardDetailsInterface[] = [];
        let remainingAmountDue = agreementAmountDue;

        customerInfo.amountUsed.cardDetails.forEach(cardData => {
            const cardAmount = Number(cardData.amount);
            if (remainingAmountDue <= CONSTANTS.ZERO_NUMBER) {
                return; // Skip this iteration if no more amount due
            }
            if (remainingAmountDue < cardAmount) {
                cardDetailsPayload.push({
                    ...cardData,
                    amount: Number(remainingAmountDue).toFixed(2),
                });
                cardData.amount = (cardAmount - remainingAmountDue).toFixed(2);
                remainingAmountDue = CONSTANTS.ZERO_NUMBER;
            } else {
                cardDetailsPayload.push(cardData);
                remainingAmountDue -= cardAmount;
            }
        });

        return cardDetailsPayload;
    } else {
        return customerInfo.amountUsed?.cardDetails || [];
    }
};

const currentStoreAgreements = (agreements: AgreementDetails[], type: string, flag: boolean, storeNumber?: string, isCurrentStore?: boolean) => {
    if (type == CONSTANTS.CLUB)
        return agreements.filter((x: AgreementDetails) => x.agreementType == type && x.selected == flag && x.otherIncomeType == CONSTANTS.EMPTY_STRING && x.storeNumber == storeNumber);
    else if (type == CONSTANTS.AGR && !isCurrentStore)
        return agreements.filter((x: AgreementDetails) => x.agreementType != CONSTANTS.CLUB && x.selected == flag && x.otherIncomeType == CONSTANTS.EMPTY_STRING && x.storeNumber == storeNumber);
    else if(type == CONSTANTS.AGR && isCurrentStore)
        return agreements.filter((x: AgreementDetails) => x.agreementType != CONSTANTS.CLUB && x.otherIncomeType == CONSTANTS.EMPTY_STRING && x.storeNumber == storeNumber);
    else
        return agreements.filter((x: AgreementDetails) => x.selected == flag && x.otherIncomeType != CONSTANTS.EMPTY_STRING);
}

const exchangeAgreementTransferSuspenseFormation = (selectedNonClubAgreements: AgreementDetails[], agreementDetails) => {
    const selectedChildrenAgreement = selectedNonClubAgreements.filter((x: AgreementDetails) => x.selected && x.agreementStatus == CONSTANTS.AGREEMENT_STATUS_ACTIVE_PEND[1] && x.parentAgreementId);
    let exchangeAgreementSuspenseTransfer: exchangeAgreementTransferSuspense[] = [];
    selectedChildrenAgreement.forEach((x: AgreementDetails) => {
        let parentAgreement = agreementDetails.find((y: AgreementDetails) => y.agreementId == x.parentAgreementId && Number(y.suspenseAmount) > 0)
        if (parentAgreement != undefined) {
            exchangeAgreementSuspenseTransfer.push({
                agreementId: x.agreementId,
                balance: parentAgreement.suspenseAmount,
            },
                {
                    agreementId: parentAgreement.agreementId,
                    balance: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
                });
        }
        // }
    });
    return exchangeAgreementSuspenseTransfer;
}